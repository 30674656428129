import { 
    IonCard, 
    IonCardHeader, 
    IonCardContent,
    IonItem, 
    IonLabel,
    IonInput,
    IonModal,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCardTitle,
    IonText,
    IonRouterLink,
    IonFab,
    IonFabButton
} from '@ionic/react';
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useForm, Controller, useController } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import { format, addYears, addDays, addMonths } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import * as adActions from '../../../../store/reducers/dashboard/ads';
import '../Ads.scss';
import CoreService from '../../../../shared/services/CoreService';
import { lfConfig } from '../../../../../Constants';
import { InitModalValues } from '../../../../shared/defaultValues/InitialValue';
import DateTimeModal from '../../../../components/Modal/DateTimeModal';
import Breadcrumbs from '../../../../components/Header/Breadcrumbs';
import ImageModal from '../../../../components/Image/ImageModal';

type FormInputs = {
    title: string;
    link: string;
    start_date: string;
    end_date: string;
}
let initialImgValues = {
    isOpen: false,
    title: '',
    actionType: '', // new or edit
    memId: '',
    repId: '',
    frmId: ''
};

const CreateAds: React.FC = () => {
    let sDate;
    let eDate;
    const dispatch = useDispatch();
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const ad = useSelector( (state:any) => state.ads.ad);
    const [addAd, setAd] = useState({ status: false, memID: '', id: '' });
    const [startDate, setStartDate] = useState<string>(ad.sdate);
    const [endDate, setEndDate] = useState<string>(ad.edate);
    const [datePickerModal, setDatePickerModal] = useState(InitModalValues);
    const [showImageModal, setShowImageModal] = useState(initialImgValues);
    const { apiBaseURL, basename } = lfConfig;
    let { id } = useParams<any>(); 
    let initialValues = {
        title: (ad && Object.keys(ad).length > 0) ? ad.title : '',
        link: (ad && Object.keys(ad).length > 0) ? ad.link: '',
        start_date: (ad && Object.keys(ad).length > 0) ? ad.sdate : '',
        end_date: (ad && Object.keys(ad).length > 0) ? ad.edate : ''
    };
    const { control, handleSubmit, setValue, reset, formState: { errors, isDirty, isValid, isSubmitting }} = useForm<FormInputs>({
        defaultValues: { ...initialValues },
        mode: "onChange"
    });
    useEffect(() => {
        if( ad && Object.keys(ad).length > 0 ){
            if(!ad.sdate){
                sDate = format(new Date(), 'yyyy-MM-dd');
                // setValue('start_date', sDate);
                if(+(ad.duration) === 1){
                    eDate = format(new Date(addYears(new Date(), 1)), 'yyyy-MM-dd');
                    // setValue('end_date', eDate);
                }else{
                    eDate = format(new Date(addMonths(new Date(), 1)), 'yyyy-MM-dd');
                    // setValue('end_date', eDate);
                }
            }
        }
        let iValues = {
               title: (ad && Object.keys(ad).length > 0) ? ad.title : '',
               link: (ad && Object.keys(ad).length > 0) ? ad.link: '',
               start_date: (ad && Object.keys(ad).length > 0 && ad.sdate) ? ad.sdate : sDate,
               end_date: (ad && Object.keys(ad).length > 0 && ad.edate) ? ad.edate : eDate
        };
        reset(iValues);
     }, [ad.title, ad.link, reset]);

    const imageModalFn = (title: string, actionType: string) => {
        setShowImageModal({ 
            ...showImageModal, 
            isOpen: true,
            title: title,
            actionType: actionType,
            memId: (authUser && Object.keys(authUser).length > 0)? authUser.ID: '',
            repId: (ad && Object.keys(ad).length > 0)? ad.rep_id: '',
            frmId: (ad && Object.keys(ad).length > 0)? ad.id: ''
        });
    }

    // Ad deafult to load callback
    const onAdCb = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch(adActions.setAd({ data: res.data }));
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
    }, [dispatch]);

    useEffect(() => {
        if( id ){
            dispatch(uiActions.setShowLoading({ loading: true }));
            CoreService.onPostFn('ad_update', {
                action: 'get_ad', 
                memID: authUser.ID,
                repID: authUser.repID,
                formID: id
            }, onAdCb);
        }
    }, [dispatch, authUser, id, onAdCb]);

    const onCallbackFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch(adActions.setAd({ data: res.data }));
            setAd({ status: true, memID: res.data.mem_id, id: res.data.id  });
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch]);
    
    const onSubmit = (data: any) => {
        if(ad.image){
            dispatch(uiActions.setShowLoading({ loading: true }));
            const fd = {
                action: 'ad_update',
                memID: authUser.ID,
                repID: authUser.repID,
                formID: id,
                ...data
            };
            CoreService.onPostFn('ad_update', fd, onCallbackFn);
        }
    }

    const updateDateHandler = (field: any, dateValue: any) => {
        if(field && dateValue){
            setValue(field, dateValue, { shouldValidate: true });
        }
    }

    const openImageHandler = () => {
        imageModalFn('Upload Sponsor Ad', 'sponsor_ads');
    }

    const adImage = ( ad && Object.keys(ad).length > 0 && ad.image) ? `${apiBaseURL}uploads/member/${ad.mem_id}/${ad.rep_id}/${ad.image}` : `${basename}/assets/img/placeholder.png`;
    if( addAd.status ){
      return <Navigate to={`/layout/ads/sponsor-ads`} />;
    }

    return (<>
        <Breadcrumbs step1={{label:'Sponsor Ads', link: `/layout/ads/sponsor-ads`}} step2={{label: 'Create Ad', link: ''}}/>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <IonCard className="card-center mt-2 mb-4">
                <IonCardHeader color="titlebg">
                    <IonCardTitle className="card-custom-title">Create your Ad
                        <IonRouterLink color="appbg" href={`/layout/ads/sponsor-ads`} className="float-right router-link-anchor" title="Ads Listing">
                            <i className="fa fa-list iconbg cursor" aria-hidden="true"></i>
                        </IonRouterLink>
                    </IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                
                    <IonGrid>
                        <IonRow>
                            <IonCol sizeMd="6" sizeXs="12">
                                <IonItem class="ion-no-padding">
                                    <IonLabel position="stacked">Ad Title (Maximum 10 Words)  <IonText color="danger">*</IonText></IonLabel>
                                    <Controller 
                                        name="title"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonInput type="text"
                                                onKeyUp={(e: any) => {
                                                    var str = e.target.value;
                                                    if( str.split(/\s+/).length > 10 ){
                                                        e.target.value = str.split(/\s+/).slice(0, 10).join(" ");
                                                    }
                                                }} 
                                                onIonChange={(e: any) => onChange(e.target.value)}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Title is required"
                                            },
                                            pattern: {
                                                value: /^\W*(\w+(\W+|$)){1,10}$/i,
                                                message: "Title shoud be lessthan 10 words"
                                            }
                                        }}
                                    />
                                </IonItem>
                                <ErrorMessage
                                    errors={errors}
                                    name="title"
                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                />
                            </IonCol>
                            <IonCol sizeMd="6" sizeXs="12">
                                <IonItem class="ion-no-padding">
                                    <IonLabel position="stacked">Ad Link  <IonText color="danger">*</IonText></IonLabel>
                                    <Controller 
                                        name="link"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonInput type="text"
                                                onIonChange={(e: any) => onChange(e.target.value)}
                                                onBlur={onBlur}
                                                value={value}
                                            />
                                        }}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Link is required"
                                            },
                                            pattern: {
                                                value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/g,
                                                // value: /^(http://)?(www\.)?[A-Za-z0-9]+\.[a-z]{2,3}/g,
                                                message: "Invalid Link"
                                            }
                                        }}
                                    />
                                </IonItem>
                                <ErrorMessage
                                    errors={errors}
                                    name="link"
                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                />
                            </IonCol>
                        </IonRow>
                        <IonRow>    
                            <IonCol sizeMd="6" sizeXs="12">
                                <IonItem class="ion-no-padding">
                                    <IonLabel position="stacked">Ad Start Date</IonLabel>
                                    <Controller 
                                        name="start_date"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonInput
                                                placeholder="YYYY-MM-DD" 
                                                // displayFormat="DD-MMM-YYYY"
                                                onClick={() => setDatePickerModal({ 
                                                    ...datePickerModal,
                                                    isOpen: true,
                                                    fieldName: 'start_date',
                                                    title: 'Ad Start Date',
                                                    presentation: 'date',
                                                    dateValue: value,
                                                    min: format(new Date(), 'yyyy-MM-dd'),
                                                    max: format(new Date(addYears(new Date(), 3)), 'yyyy-MM-dd')
                                                })}
                                                onIonChange={(selected: any) => {
                                                    if(selected.target.value){
                                                        const sDateChange = format(new Date(selected.target.value), 'yyyy-MM-dd');
                                                        onChange(selected.target.value);
                                                        setStartDate(sDateChange);
                                                    }
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                disabled={true}
                                            />
                                        }}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Start Date is required"
                                            }
                                        }}
                                    />
                                </IonItem>
                                <ErrorMessage
                                    errors={errors}
                                    name="start_date"
                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                />
                                <p><IonText color="danger">*</IonText> This field can't change once the Ad has started.</p>
                            </IonCol>
                            <IonCol sizeMd="6" sizeXs="12">
                                <IonItem class="ion-no-padding">
                                    <IonLabel position="stacked">Ad End Date</IonLabel>
                                    <Controller 
                                        name="end_date"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonInput
                                                placeholder="YYYY-MM-DD"
                                                // displayFormat="DD-MMM-YYYY"
                                                onClick={() => setDatePickerModal({ 
                                                    ...datePickerModal,
                                                    isOpen: true,
                                                    fieldName: 'end_date',
                                                    title: 'Ad End Date',
                                                    presentation: 'date',
                                                    dateValue: value,
                                                    min: startDate? startDate : format(new Date(), 'yyyy-MM-dd'),
                                                    max: endDate? endDate : format(new Date(), 'yyyy-MM-dd'),
                                                })}
                                                onIonChange={(selected: any) => {
                                                    if(selected.target.value){
                                                        const eDateChange = format(new Date(selected.target.value), 'yyyy-MM-dd');
                                                        onChange(selected.target.value);
                                                        setEndDate(eDateChange);
                                                    }
                                                }}
                                                onBlur={onBlur}
                                                value={value}
                                                disabled={true}
                                            />
                                        }}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "End Date is required"
                                            }
                                        }}
                                    />
                                </IonItem>
                                <ErrorMessage
                                    errors={errors}
                                    name="end_date"
                                    render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                />
                                <p><IonText color="danger">*</IonText> This field can't change once the Ad has started.</p>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol sizeMd="6" sizeXs="12">
                                <IonItem class="ion-no-padding" lines="none" onClick={() => openImageHandler()}>
                                    <IonLabel position="stacked">Ad Image <IonText color="danger">*</IonText></IonLabel>
                                    <div className="profile-logo mt-3">
                                        <img src={adImage} alt="Ad Media" />
                                        <IonFab  vertical="bottom" horizontal="end" slot="fixed">
                                            <IonFabButton color="appbg" size="small"><i className="fa fa-pencil fa-lg cursor" aria-hidden="true"></i></IonFabButton>
                                        </IonFab>
                                    </div>
                                </IonItem>
                                { (!isDirty && !isValid && ad && Object.keys(ad).length > 0 && !ad.image ) &&
                                    <div className="invalid-feedback">Image is required</div>
                                }
                                
                            </IonCol>
                        </IonRow>
                        
                    </IonGrid>
                    <IonButton color="appbg" className="ion-margin-top mt-4 mb-3 float-right" type="submit" disabled={!ad.image || isSubmitting}>
                        Submit
                    </IonButton>
                    
                </IonCardContent>
            </IonCard>
        </form>
        <IonModal backdropDismiss={false} isOpen={datePickerModal.isOpen} className='view-modal-wrap'>
            { datePickerModal.isOpen === true &&  <DateTimeModal
                datePickerModal={datePickerModal}
                setDatePickerModal={setDatePickerModal}
                updateDateHandler={updateDateHandler}
        /> }
        </IonModal>
        <IonModal backdropDismiss={false} isOpen={showImageModal.isOpen} className='view-modal-wrap'>
            { ad && Object.keys(ad).length > 0 && showImageModal.isOpen === true &&  <ImageModal
            showImageModal={showImageModal}
            setShowImageModal={setShowImageModal} 
           /> }
        </IonModal>
    </>);
};
export default CreateAds;
  