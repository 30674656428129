import axios from 'axios';
// import { Observable } from 'rxjs';
import Stripe from 'stripe';
import { lfConfig } from '../../../Constants';

var StripeService = (function() {
  // Stripe Test Subscription Plan ID
  const stripeLocalCAMonthly = 'price_1Ng4mVLI5aukiuLrCK3PbQfw';
  const stripeLocalNonCAMonthly = 'price_1Nfm1yLI5aukiuLrroMFeEsH';
  const stripeLocalCAYearly = 'price_1NfmBFLI5aukiuLrQpVTrI9Q';
  const stripeLocalNonCAYearly = 'price_1NfmCALI5aukiuLrZvEJmPdw';
  const stripeRegionalCAMonthly = 'price_1NfmKfLI5aukiuLrmgSLlng9';
  const stripeRegionalNonCAMonthly = 'price_1NfmLrLI5aukiuLrAGLiEQaO';
  const stripeRegionalCAYearly = 'price_1NfmMoLI5aukiuLrNunmfKbl';
  const stripeRegionalNonCAYearly = 'price_1NfmNPLI5aukiuLrio80O8SO';
  const stripeNationalCAMonthly = 'price_1Ng4nsLI5aukiuLrQXFP6PMk';
  const stripeNationalNonCAMonthly = 'price_1Ng4qDLI5aukiuLrFs6o55sA';
  const stripeNationalCAYearly = 'price_1Ng4rgLI5aukiuLrNVHl3g7t';
  const stripeNationalNonCAYearly = 'price_1Ng4uMLI5aukiuLrIEvJra6M';
  const stripeInternationalCAMonthly = 'price_1Ng4wnLI5aukiuLrEewtwP2C';
  const stripeInternationalNonCAMonthly = 'price_1Ng4yMLI5aukiuLrlpJJ9oZy';
  const stripeInternationalCAYearly = 'price_1Ng5BJLI5aukiuLrnVd30FoF';
  const stripeInternationalNonCAYearly = 'price_1Ng5CoLI5aukiuLr2yTkZIcF';

  // Paypal Sandbox Subscription Plan ID
  const paypalLocalCAMonthly = 'P-08824307YU002284VMT62PSQ';
  const paypalLocalNonCAMonthly = 'P-5F9568357X9527808MT63NEQ';
  const paypalLocalCAYearly = 'P-0JX92462H3813364LMT63N3A';
  const paypalLocalNonCAYearly = 'P-8CD96419TP653000SMT63OMQ';
  const paypalRegionalCAMonthly = 'P-95N89105GW2073730MT665BY';
  const paypalRegionalNonCAMonthly = 'P-06E43571FG908800PMT665UY';
  const paypalRegionalCAYearly = 'P-34B57206KF2814356MT666GI';
  const paypalRegionalNonCAYearly = 'P-70H13366FF211530VMT666VY';
  const paypalNationalCAMonthly = 'P-3YD26961M1910584WMT67AOA';
  const paypalNationalNonCAMonthly = 'P-9SR80158U2545722UMT67BDA';
  const paypalNationalCAYearly = 'P-0Y2396820Y6602411MT67BQQ';
  const paypalNationalNonCAYearly = 'P-6GG0043488543725AMT67CCQ';
  const paypalInternationalCAMonthly = 'P-67583588V7839283FMT67DEA';
  const paypalInternationalNonCAMonthly = 'P-7T021134PS439851EMT67D6I';
  const paypalInternationalCAYearly = 'P-04079593WK964682UMT67EMQ';
  const paypalInternationalNonCAYearly = 'P-5S372351G19470408MT67FGI';

  // Stripe Live Subscription Plan ID
  // const stripeLocalCAMonthly = 'price_1Ns3kCJflWFrw1FrFYPBccsO';
  // const stripeLocalNonCAMonthly = 'price_1Ns3m2JflWFrw1FrKCBHtjE7';
  // const stripeLocalCAYearly = 'price_1Ns3n0JflWFrw1FrcPf4k61q';
  // const stripeLocalNonCAYearly = 'price_1Ns4LdJflWFrw1Frn7QBJOPL';
  // const stripeRegionalCAMonthly = 'price_1Ns4MNJflWFrw1Fr4WsOyH7V';
  // const stripeRegionalNonCAMonthly = 'price_1Ns4NkJflWFrw1FrfggUcBbC';
  // const stripeRegionalCAYearly = 'price_1Ns4STJflWFrw1FrpkTGqtDP';
  // const stripeRegionalNonCAYearly = 'price_1Ns4TBJflWFrw1FrWxjre0dM';
  // const stripeNationalCAMonthly = 'price_1Ns4TqJflWFrw1Frex4IzmzV';
  // const stripeNationalNonCAMonthly = 'price_1Ns4UXJflWFrw1FrXRO5Ja1s';
  // const stripeNationalCAYearly = 'price_1Ns4VFJflWFrw1Freoh7Qxp8';
  // const stripeNationalNonCAYearly = 'price_1Ns4XrJflWFrw1FrD9gPwBwQ';
  // const stripeInternationalCAMonthly = 'price_1Ns4Z1JflWFrw1FrELJpQ8Fe';
  // const stripeInternationalNonCAMonthly = 'price_1Ns4ZdJflWFrw1FruBWFSCo5';
  // const stripeInternationalCAYearly = 'price_1Ns4aEJflWFrw1Frv216K8IM';
  // const stripeInternationalNonCAYearly = 'price_1Ns4avJflWFrw1FrPeZakv8X';

  // Paypal Live Subscription Plan ID
  // const paypalLocalCAMonthly = 'P-2CJ78577KU7870518MUEM7QY';
  // const paypalLocalNonCAMonthly = 'P-5RR621639P152340RMUENCGI';
  // const paypalLocalCAYearly = 'P-17B449513C5975050MUENNJQ';
  // const paypalLocalNonCAYearly = 'P-15H63221M4303271NMUENN3I';
  // const paypalRegionalCAMonthly = 'P-7F5841690X5134131MUENQXY';
  // const paypalRegionalNonCAMonthly = 'P-5B713597CF8855247MUENRII';
  // const paypalRegionalCAYearly = 'P-2VF322544C174891VMUENRWQ';
  // const paypalRegionalNonCAYearly = 'P-6EX37664TH6290136MUENSGA';
  // const paypalNationalCAMonthly = 'P-7X287276FM322750MMUENSUI';
  // const paypalNationalNonCAMonthly = 'P-7AF93697NV7096537MUENS7A';
  // const paypalNationalCAYearly = 'P-7WU03379H1140394MMUENTKI';
  // const paypalNationalNonCAYearly = 'P-5EG04740WD1274714MUENUBI';
  // const paypalInternationalCAMonthly = 'P-5H002657JF903873UMUENVWY';
  // const paypalInternationalNonCAMonthly = 'P-5NK02088MD385845RMUENWCY';
  // const paypalInternationalCAYearly = 'P-7DT74284GM392502HMUENWQQ';
  // const paypalInternationalNonCAYearly = 'P-8UK59484UP944770PMUENW5Q';

    const stripe = new Stripe(lfConfig.stripeSecretKey, {
        apiVersion: '2022-11-15',
    });

    const getStripePriceId = (location: number, duration: number, country: string) => {
      let priceid = '';
      location = +(location);
      duration = +(duration); 
      if(country === 'CA'){ // For Canada Users
        if(duration === 1){ // For Yearly
          if(location === 1){
            priceid = stripeLocalCAYearly;
          }else if(location === 2){
            priceid = stripeRegionalCAYearly;
          }else if(location === 3){
            priceid = stripeNationalCAYearly;
          }else if(location === 4){
            priceid = stripeInternationalCAYearly;
          }
        }else{ // For Monthly
          if(location === 1){
            priceid = stripeLocalCAMonthly;
          }else if(location === 2){
            priceid = stripeRegionalCAMonthly;
          }else if(location === 3){
            priceid = stripeNationalCAMonthly;
          }else if(location === 4){
            priceid = stripeInternationalCAMonthly;
          }
        }
      }else{ // For Non Canada Users
        if(duration === 1){ // For Yearly
          if(location === 1){
            priceid = stripeLocalNonCAYearly;
          }else if(location === 2){
            priceid = stripeRegionalNonCAYearly;
          }else if(location === 3){
            priceid = stripeNationalNonCAYearly;
          }else if(location === 4){
            priceid = stripeInternationalNonCAYearly;
          }
        }else{ // For Monthly
          if(location === 1){
            priceid = stripeLocalNonCAMonthly;
          }else if(location === 2){
            priceid = stripeRegionalNonCAMonthly;
          }else if(location === 3){
            priceid = stripeNationalNonCAMonthly;
          }else if(location === 4){
            priceid = stripeInternationalNonCAMonthly;
          }
        }
      } 
      return priceid;
    };

    const getPaypalSubscriptionId = (location: number, duration: number, country: string) => {
      let paysubsid = '';
      location = +(location);
      duration = +(duration); 
      if(country === 'CA'){ // For Canada Users
        if(duration === 1){ // For Yearly
          if(location === 1){
            paysubsid = paypalLocalCAYearly;
          }else if(location === 2){
            paysubsid = paypalRegionalCAYearly;
          }else if(location === 3){
            paysubsid = paypalNationalCAYearly;
          }else if(location === 4){
            paysubsid = paypalInternationalCAYearly;
          }
        }else{ // For Monthly
          if(location === 1){
            paysubsid = paypalLocalCAMonthly;
          }else if(location === 2){
            paysubsid = paypalRegionalCAMonthly;
          }else if(location === 3){
            paysubsid = paypalNationalCAMonthly;
          }else if(location === 4){
            paysubsid = paypalInternationalCAMonthly;
          }
        }
      }else{ // For Non Canada Users
        if(duration === 1){ // For Yearly
          if(location === 1){
            paysubsid = paypalLocalNonCAYearly;
          }else if(location === 2){
            paysubsid = paypalRegionalNonCAYearly;
          }else if(location === 3){
            paysubsid = paypalNationalNonCAYearly;
          }else if(location === 4){
            paysubsid = paypalInternationalNonCAYearly;
          }
        }else{ // For Monthly
          if(location === 1){
            paysubsid = paypalLocalNonCAMonthly;
          }else if(location === 2){
            paysubsid = paypalRegionalNonCAMonthly;
          }else if(location === 3){
            paysubsid = paypalNationalNonCAMonthly;
          }else if(location === 4){
            paysubsid = paypalInternationalNonCAMonthly;
          }
        }
      } // console.log(paysubsid);
      return paysubsid;
    };
    
    const getLocationText = (location: number) => {
      location = +(location);
      let description = '';
      if(location === 1){
        description = "Local";
      }else if(location === 2){
          description = "Regional";
      }else if(location === 3){
          description = "National";
      }else if(location === 4){
          description = "International";
      }
      return description;
    };
    
  
    return {
      // server api
      getStripePriceId,
      getPaypalSubscriptionId,
      getLocationText
    }
  
  })();
  
  export default StripeService;