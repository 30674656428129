import React, { useEffect } from 'react';
import { IonApp } from '@ionic/react';
import { setupIonicReact } from '@ionic/react';
import LFRoutes from './Routes';
import { SplashScreen } from '@capacitor/splash-screen';

setupIonicReact({
  mode: 'md'
});


const App: React.FC = () => {
  
  // useEffect(() => {
  //   SplashScreen.show();
  // })

  // useEffect( () => { 
    
  //   async function fetchSplashScreen() {
  //     await SplashScreen.show({
  //       showDuration: 10000,
  //       autoHide: true,
  //     });
  //   }
  //   fetchSplashScreen()
  // }, []); 

  return (
    <IonApp>
        <LFRoutes />
    </IonApp>
  );
}

export default App;
