import { 
    IonCard, 
    IonCardHeader, 
    IonCardContent,
    IonItem, 
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonGrid,
    IonRow,
    IonCol,
    IonCardTitle,
    IonText,
    IonRadioGroup,
    IonRadio
} from '@ionic/react';
import React, { useState, useCallback, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
// import {unserialize} from 'php-serialize'
import { useDispatch, useSelector } from 'react-redux';
import * as uiActions from '../../../../store/reducers/ui';
import '../Ads.scss';
import CoreService from '../../../../shared/services/CoreService';
import { lfConfig } from '../../../../../Constants';
import Breadcrumbs from '../../../../components/Header/Breadcrumbs';
// import useDebounce from '../../../../hooks/useDebounce';

const BuyAds: React.FC = () => {
    let hasFocus = false;
    let listReps: any = null;
    const dispatch = useDispatch();
    const { PARTNER_ADS } = lfConfig;
    let { id } = useParams<any>();
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const loadingState = useSelector( (state:any) => state.ui.loading);
    const [ reps, setReps ] = useState([]);
    const [ coupon, setCoupon ] = useState({ code: '', status: false });
    const [adLog, setAdLog] = useState<any>({ 
        country: '',
        attach_rep: '',
        duration: 1,
        recurring: 'yes',
        space_total: '0.00', 
        tax: '0.00', 
        tax_total: '0.00',
        total: '0.00',
        init: false, 
        id: id? id: 0,
        log: {}
    });
    // const debouncedValue = useDebounce<string>(adLog.duration, 2500)

    let initialValues = {
        attach_rep: '',
        duration: "1",
        recurring: 'yes',
        coupon_code: '',
        payment_type: 'credit_card'
        
    };
    const { control, handleSubmit, formState: { errors }, getValues, setValue } = useForm<any>({
        defaultValues: { ...initialValues },
        mode: "onChange"
    });

    useEffect(()=>{
        if(coupon.code.length <= 0){ 
            setCoupon( { ...coupon, status: false } );
        }
    },[coupon.code]);

    // For Reps default to load
    const onProfileCb = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            setReps([]);
            setReps(res.reps);
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
    }, [dispatch, setReps]);
    useEffect(() => {
        if( authUser.ID ){
            dispatch(uiActions.setShowLoading({ loading: true }));
            CoreService.onPostFn('get_member', {'action': 'get_reps', memID: authUser.ID }, onProfileCb);
        }
    }, [dispatch, onProfileCb, authUser.ID]);

    const onFeeCbFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            const data = getValues();
            setAdLog({ 
                ...adLog, 
                attach_rep: data.attach_rep,
                duration: data.duration,
                location: res.data.location,
                country: res.data.country, 
                space_total: res.data.space_total, 
                tax: res.data.tax, 
                tax_total: res.data.tax_total,
                total: res.data.total 
            });
        }else{
            dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
    }, [setAdLog, dispatch, adLog]);
    const onPremiumDealFeeCalc = useCallback((loading:boolean) => { 
        const data = getValues(); 
        if( data.attach_rep && data.duration ){ 
            if( !loadingState.showLoading ){
                dispatch(uiActions.setShowLoading({ loading: true }));
            }
            const fd: any = {
                action: 'ads_fees_calculation',
                action_type: PARTNER_ADS,
                memID: authUser.ID,
                repID: data.attach_rep,
                ...data
            };
            CoreService.onPostFn('item_purchase', fd, onFeeCbFn);
        }
    },[getValues]);
    useEffect(() => {
        // console.log(adLog.attach_rep, adLog.duration);
        // Delay calling api on input typing
        if( adLog.attach_rep && adLog.duration ){ 
            // const delayDebounceFn = setTimeout(() => {
                onPremiumDealFeeCalc(false);
            // }, 3000);
            // return () => clearTimeout(delayDebounceFn);
            return;
        }
    }, [adLog.attach_rep, adLog.duration, onPremiumDealFeeCalc]);

    const resetAction = () => {
        setValue('attach_rep', "", { shouldValidate: true });
        setValue('duration', "1", { shouldValidate: true });
        setValue('recurring', "yes", {shouldValidate: true});
        setValue('payment_type', 'credit_card', { shouldValidate: true });
        setAdLog({ 
            country: '',
            attach_rep: '',
            duration: 1,
            recurring: 'yes',
            space_total: '0.00', 
            tax: '0.00', 
            tax_total: '0.00',
            total: '0.00',
            init: false, 
            id: id? id: 0,
            log: {}
        });
        onPremiumDealFeeCalc(false);
    }

    const onCouponCBFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            setCoupon( { ...coupon, code: getValues('coupon_code'), status: true } );
            setAdLog({ 
                ...adLog,
                recurring: 'no'
            });
            setValue('recurring', 'no');
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch, setCoupon]);

    const handleApplyCoupon = () => {
        const coupon_code = getValues('coupon_code'); 
        // console.log(coupon_code);    
        // dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: 'ad_apply_coupon',
            memID: authUser.ID,
            repID: authUser.repID,
            action_type: PARTNER_ADS,
            country: adLog.country,
            location: adLog.location,
            coupon_code: coupon_code
        };
        CoreService.onPostFn('ad_update', fd, onCouponCBFn);    

    }

    const onCallbackFn = useCallback((res: any) => { // console.log(res.data);
        if(res.status === 'SUCCESS'){
            setAdLog({ ...adLog, id: res.data.id, init: true, log: res.data });
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch, adLog]);
    
    const onSubmit = (data: any) => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const fd = {
            action: id? 'ad_purchase_update': 'ad_purchase',
            memID: authUser.ID,
            repID: authUser.repID,
            action_type: PARTNER_ADS,
            country: adLog.country,
            location: adLog.location,
            ...data
        };
        if( id ){
            fd.formID = id;
        } 
        CoreService.onPostFn('item_purchase', fd, onCallbackFn);
    }

    if( reps && reps.length > 0 ){
        listReps = reps.map((rep: any) =>
            <IonSelectOption value={rep.rep_id} key={rep.rep_id}>{ `${rep.firstname} ${rep.lastname}`}</IonSelectOption> 
        );
    }

    // const duration = getValues('duration');
    if( adLog.id && adLog.init === true ){
        if(coupon.status === true){ // console.log(adLog);
            return <Navigate to={`/layout/ads/create-ad/${adLog.log.form_id}/${adLog.log.mem_id}/1`} />;
        }else{
            return <Navigate to={`/layout/ads/ads-payment/${adLog.id}/${authUser.ID}/${authUser.repID}`} />;
        }
    }

    return (<>
        <Breadcrumbs step1={{label:'Sponsor Ads', link: `/layout/ads/sponsor-ads`}} step2={{label: 'Buy Ad', link: ''}}/>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <IonCard className="card-center mt-2 mb-4">
            <IonCardHeader color="titlebg">
                <IonCardTitle className="card-custom-title">Purchase your Sponsor Ad</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                <div className="mx-2 my-3">
                    <p>Boost your business and uplift your community with Local First Sponsor Ads! Promote your brand while supporting local ventures. Our pricing is distance-based, ensuring your ad reaches the perfect audience. Help keep this app free for local users. Join us in driving community growth and success through your Local First sponsored ad today!</p>
                </div>
                <IonGrid>
                    <IonRow>
                        <IonCol sizeMd="6" sizeXs="12">
                            <IonRow>
                                <IonCol>
                                    <IonItem class="ion-no-padding">
                                        <IonLabel position="stacked">Rep Profile Preference  <IonText color="danger">*</IonText></IonLabel>
                                        <Controller 
                                            name="attach_rep"
                                            control={control}
                                            render={({ field: {onChange, onBlur, value} }) => {
                                                return <IonSelect
                                                placeholder="Select Rep Profile"
                                                // onFocus={() => hasFocus = !hasFocus} 
                                                // onIonChange={e => hasFocus && onChange(e.detail.value)}
                                                onIonChange={(selected: any) => {
                                                    onChange(selected.detail.value);
                                                    setAdLog({ ...adLog, attach_rep: selected.target.value });
                                                }}
                                                onBlur={onBlur}
                                                key={value.toString()}
                                                value={value}
                                            >{listReps}</IonSelect>
                                            }}
                                            rules={{ 
                                                required: {
                                                    value: true,
                                                    message: "Rep Preferrence is required"
                                                }
                                            }}
                                        />
                                    </IonItem>
                                    <ErrorMessage
                                        errors={errors}
                                        name="attach_rep"
                                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                    />
                                </IonCol>
                            </IonRow>
                            
                            <IonRow>
                                <IonCol>
                                    <IonItem class="ion-no-padding">
                                        <IonLabel position="stacked">Duration  <IonText color="danger">*</IonText></IonLabel>
                                        <Controller 
                                            name="duration"
                                            control={control}
                                            render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonRadioGroup
                                                onIonChange={(e: any) => {
                                                    onChange(e.target.value);
                                                    setAdLog({ ...adLog, duration: e.target.value });
                                                }} 
                                                onBlur={onBlur}
                                                value={value}>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem lines="none">
                                                                <IonLabel color="medium">Monthly</IonLabel>
                                                                <IonRadio slot="start" value="2" />
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonItem lines="none">
                                                                <IonLabel color="medium">Yearly</IonLabel>
                                                                <IonRadio slot="start" value="1" />
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonRadioGroup>
                                            }}
                                            rules={{ 
                                                required: {
                                                    value: true,
                                                    message: "Duration is required"
                                                }
                                            }}
                                        />
                                    </IonItem>
                                    <ErrorMessage
                                        errors={errors}
                                        name="duration"
                                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                    />
                                    {/* {errors.payment_type && <div className="invalid-feedback">{errors.payment_type.message}</div>} */}
                                </IonCol>
                            </IonRow> 
                            <IonRow>
                                <IonCol>
                                    <IonItem class="ion-no-padding">
                                        <IonLabel position="stacked">Payment Recurring  <IonText color="danger">*</IonText></IonLabel>
                                        <Controller 
                                            name="recurring"
                                            control={control}
                                            render={({ field: {onChange, onBlur, value} }) => {
                                            return <IonRadioGroup
                                                onIonChange={(e: any) => {
                                                    onChange(e.target.value);
                                                    setAdLog({ ...adLog, duration: e.target.value });
                                                }} 
                                                onBlur={onBlur}
                                                value={value}>
                                                    <IonRow>
                                                        <IonCol>
                                                            <IonItem lines="none">
                                                                <IonLabel color="medium">Yes</IonLabel>
                                                                <IonRadio slot="start" value="yes" />
                                                            </IonItem>
                                                        </IonCol>
                                                        <IonCol>
                                                            <IonItem lines="none">
                                                                <IonLabel color="medium">No</IonLabel>
                                                                <IonRadio slot="start" value="no" />
                                                            </IonItem>
                                                        </IonCol>
                                                    </IonRow>
                                            </IonRadioGroup>
                                            }}
                                            rules={{ 
                                                required: {
                                                    value: true,
                                                    message: "Recurring is required"
                                                }
                                            }}
                                        />
                                    </IonItem>
                                    <ErrorMessage
                                        errors={errors}
                                        name="recurring"
                                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                    />
                                    {/* {errors.payment_type && <div className="invalid-feedback">{errors.payment_type.message}</div>} */}
                                </IonCol>
                            </IonRow>    
                        </IonCol>

                        <IonCol sizeMd="6" sizeXs="12">
                            <p><b>Based on the Rep location(s) this ad will cost</b></p>
                            <div className="adCost"> Ad Space Costs: <strong>${parseFloat(adLog.space_total).toFixed(2)}</strong></div>
                            { adLog.country === 'CA' && <div className="tax"> GST/Federal Tax({parseInt(adLog.tax)}%): <strong>${parseFloat(adLog.tax_total).toFixed(2)}</strong></div>}
                            <hr />
                            <div className="total mb-3">Total Cost: <strong>${parseFloat(adLog.total).toFixed(2)}</strong></div>
                            { getValues('attach_rep') && <IonRow>
                                <IonCol sizeMd="6" sizeXs="12">
                                    <IonItem class="ion-no-padding">
                                        <IonLabel position="stacked">Coupon code </IonLabel>
                                        <Controller 
                                            name="coupon_code"
                                            control={control}
                                            render={({ field: {onChange, onBlur, value} }) => {
                                                return <IonInput type="text"
                                                    // onIonChange={(e: any) => {
                                                    //     onChange(e.target.value);
                                                    // }
                                                    onIonChange={(selected: any) => {
                                                        onChange(selected.detail.value);
                                                        setCoupon({ ...coupon, code: selected.detail.value });
                                                    }}
                                                    onBlur={onBlur}
                                                    value={value}
                                                />
                                            }}
                                            rules={{
                                                required: false,
                                                pattern: {
                                                    value: /^[A-Z0-9]{3,10}$/i,
                                                    message: "Invalid Coupon Code"
                                                }
                                            }}
                                        />
                                    </IonItem>
                                    <ErrorMessage
                                        errors={errors}
                                        name="coupon_code"
                                        render={({ message }) => <div className="invalid-feedback">{message}</div>}
                                    />
                                </IonCol>
                                <IonCol sizeMd="6" sizeXs="12">
                                    <IonButton color="warning" className="ion-margin-top mt-4 float-left mb-3" type="button" onClick={handleApplyCoupon} disabled={coupon.status || !getValues('coupon_code')}>
                                        Apply Coupon
                                    </IonButton>
                                </IonCol>
                            </IonRow>  } 

                            { !coupon.status && <IonRow>
                                <IonCol>
                                    <IonLabel>Choose your payment method</IonLabel>

                                    <Controller 
                                        name="payment_type"
                                        control={control}
                                        render={({ field: {onChange, onBlur, value} }) => {
                                        return <IonRadioGroup
                                            onIonChange={onChange} 
                                            onBlur={onBlur}
                                            value={value}>
                                                <IonRow>
                                                    <IonCol>
                                                        <IonItem lines="none">
                                                            <IonLabel color="medium">Credit Card</IonLabel>
                                                            <IonRadio slot="start" value="credit_card" />
                                                        </IonItem>
                                                    </IonCol>
                                                    <IonCol>
                                                        <IonItem lines="none">
                                                            <IonLabel color="medium">Paypal</IonLabel>
                                                            <IonRadio slot="start" value="paypal" />
                                                        </IonItem>
                                                    </IonCol>
                                                </IonRow>
                                        </IonRadioGroup>
                                        }}
                                        rules={{ 
                                            required: {
                                                value: true,
                                                message: "Payment Type is required"
                                            }
                                        }}
                                    />
                                    {/* {errors.payment_type && <div className="invalid-feedback">{errors.payment_type.message}</div>} */}
                                </IonCol>
                            </IonRow>}
                        </IonCol>    
                    </IonRow>    
                </IonGrid>
                
                <IonButton color="danger" className="ion-margin-top mt-4 float-right mb-3" type="button" onClick={resetAction}>
                    Cancel
                </IonButton>
                <IonButton color="appbg" className="ion-margin-top mt-4 float-right mb-3" type="submit" disabled={!coupon.status && getValues('coupon_code').length > 0 }>
                    Purchase
                </IonButton>
                
            </IonCardContent>
        </IonCard>
        </form>
    </>);
};
export default React.memo(BuyAds);
  