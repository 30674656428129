import React, { useState, useCallback, useEffect } from "react";
import ReactDOM from "react-dom";
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	PayPalScriptProvider,
	PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";
import './Payment.scss';
import { lfConfig } from '../../../Constants';
import CoreService from '../../shared/services/CoreService';
import * as uiActions from '../../store/reducers/ui';
import * as frmdataActions from '../../store/reducers/common';
import StripeService from '../../shared/services/StripeService';

// declare global {
//     interface Window {
//         paypal:any;
//     }
// }
// let paypal: any;
// const PayPalButton = paypal.Buttons.driver("react", { React, ReactDOM });
// const PayPalButtons = window.paypal.Buttons.driver("react", { React, ReactDOM });

const ButtonWrapper = ({ type }) => {
    let { id } = useParams<any>();
    const { LOCAL_DEAL, PARTNER_ADS } = lfConfig;
	const [{ options }, dispatch] = usePayPalScriptReducer();
    const dispatch1 = useDispatch();
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const itemData = useSelector( (state:any) => state.formdata.item);
    const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
    let priceid = StripeService.getPaypalSubscriptionId(itemData.location, itemData.duration, itemData.country);

	useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                intent: "subscription",
            },
        });
    }, [type]);

    const onCallbackFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch1(frmdataActions.setFormData({ data: res.data, key: 'item' }));
            setPaymentStatus(true);
        }
        dispatch1(uiActions.setShowLoading({ loading: false }));
        dispatch1(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch1]);

    const onApprove = async (data: any, actions: any) => { // console.log(data, actions);
        dispatch1(uiActions.setShowLoading({ loading: true }));
        if(data&& Object.keys(data).length > 0 && data.orderID){
            const fd = {
                memID: authUser.ID,
                repID: authUser.repID,
                formID: id,
                type: 'paypal',
                action: itemData.type,
                amount: itemData.price,
                id: data.orderID,
                paypalData: data,
                // billing: billing_details,
                ...data
            };
            CoreService.onPostFn('payment', fd, onCallbackFn);
        }else{
            dispatch1(uiActions.setShowLoading({ loading: false }));
            dispatch1(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: 'Payment Failed. Try again' }));
        } 
        // console.log(order);
    }
    const onError = (error: any) => {
        dispatch1(uiActions.setShowLoading({ loading: false }));
        dispatch1(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: error }));
    }

    if( itemData && Object.keys(itemData).length > 0 && paymentStatus){
        if( itemData.type === LOCAL_DEAL ){
            return <Navigate to={`/layout/deals/add-deal/${itemData.form_id}/${itemData.mem_id}/1`} />;
        }else if( itemData.type === PARTNER_ADS ){ 
            return <Navigate to={`/layout/ads/create-ad/${itemData.form_id}/${itemData.mem_id}/1`} />;
        }
    }

	return (<PayPalButtons
		createSubscription={(data, actions) => { // console.log(data, actions);
			return actions.subscription
				.create({
					plan_id: priceid,
				})
				.then((orderId) => {
					// Your code here after create the order
                    // console.log(orderId);
					return orderId;
				});
		}}
		style={{
			label: "subscribe",
		}}
        onApprove={(data:any, actions:any) => onApprove(data, actions)}
        onError={(error: any) => onError(error)}
        onCancel={(error: any) => onError(error)}
	/>);
}

export default function Paypal() {
    const dispatch = useDispatch();
    let { id } = useParams<any>();
    const { LOCAL_DEAL, PARTNER_ADS, paypalClientId } = lfConfig;
    const authUser = useSelector( (state:any) => state.auth.data.user);
    const itemData = useSelector( (state:any) => state.formdata.item);
    const [paymentStatus, setPaymentStatus] = useState<boolean>(false);
    
    const onCallbackFn = useCallback((res: any) => {
        if(res.status === 'SUCCESS'){
            dispatch(frmdataActions.setFormData({ data: res.data, key: 'item' }));
            setPaymentStatus(true);
        }
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: res.status, message: res.message }));
    }, [dispatch]);

    const createOrder = (data: any, actions: any) => {
        if(itemData && Object.keys(itemData).length > 0){
            let desc = '';
            if(itemData.type === 'local_deal'){
                desc = 'Purchase LocalFirst Deal'
            }
            return actions.order.create({
                intent: "CAPTURE",
                purchase_units: [
                    {
                        description: desc,
                        amount: {
                            currency_code: "USD",
                            value: itemData.price,
                        },
                    }
                ]
            });
        }    
    }

    const onApprove = async (data: any, actions: any) => {
        dispatch(uiActions.setShowLoading({ loading: true }));
        const order = await actions.order.capture();
        if(order && Object.keys(order).length > 0 && order.status === 'COMPLETED'){
            const fd = {
                memID: authUser.ID,
                repID: authUser.repID,
                formID: id,
                type: 'paypal',
                action: itemData.type,
                amount: itemData.price,
                id: order.id,
                paypalData: order,
                // billing: billing_details,
                ...data
            };
            CoreService.onPostFn('payment', fd, onCallbackFn);
        }else{
            dispatch(uiActions.setShowLoading({ loading: false }));
            dispatch(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: 'Payment Failed. Try again' }));
        }
        // console.log(order);
    }
    const onError = (error: any) => {
        dispatch(uiActions.setShowLoading({ loading: false }));
        dispatch(uiActions.setShowToast({ isShow: true, status: 'ERROR', message: error }));
    }

    if( itemData && Object.keys(itemData).length > 0 && paymentStatus){
        if( itemData.type === LOCAL_DEAL ){
            return <Navigate to={`/layout/deals/add-deal/${itemData.form_id}/${itemData.mem_id}/1`} />;
        }else if( itemData.type === PARTNER_ADS ){
            return <Navigate to={`/layout/ads/create-ad/${itemData.form_id}/${itemData.mem_id}/1`} />;
        }
    }
    
    return (
        <div className="p-5">
            { itemData && Object.keys(itemData).length > 0 && <>
                { itemData.recurring === 'yes' && 
                    <PayPalScriptProvider
                        options={{
                            clientId: paypalClientId,
                            components: "buttons",
                            intent: "subscription",
                            vault: true,
                        }}
                    >
                        <ButtonWrapper type="subscription" />
                    </PayPalScriptProvider>
                }
                { itemData.recurring === 'no' && 
                    // <PayPalScriptProvider
                    //     options={{
                    //         clientId: "test",
                    //         components: "buttons",
                    //         dataNamespace: "paypalOrder"
                    //     }}
                    // >
                    <PayPalScriptProvider
                        options={{
                            clientId: paypalClientId,
                            components: "buttons",
                            vault: true,
                        }}
                    >
                        <PayPalButtons
                            createOrder={(data:any, actions:any) => createOrder(data, actions)}
                            onApprove={(data:any, actions:any) => onApprove(data, actions)}
                            onError={(error: any) => onError(error)}
                            onCancel={(error: any) => onError(error)}
                        />
                    </PayPalScriptProvider>
                    
                }
            </>}
        </div>
    ); 
}